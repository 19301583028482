<script setup lang="ts">
import api from '@/api';
import { updateToken } from '@/api/backend/_http';
import type { UtmMetricDto } from '@/api/backend/users/applications';
import { AppValues, CurrentKey } from '@/helpers/const';
import { initDeclarationMachine } from '@/helpers/loanDeclarationAppMachine';
import { initRequestIncomeExpenseMachine } from '@/helpers/loanRequestIncomeExpenseMachine';
import { useApplicationApi } from '@/helpers/useApplicationApi';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import { useDeclarationProcess } from '@/helpers/useDeclarationProcess';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import { useRequestIncomeExpenseProcess } from '@/helpers/useRequestIncomeExpenseProcess';
import {
  DeclarationProcessStep,
  DeclarationProcessStepNames,
  RequestIncomeExpenseProcessStep,
  RequestIncomeExpenseProcessStepNames,
  StepStatus,
  type AppUser,
  type DeclarationProcessStatus,
  type LoanApplication,
  type RequestIncomeExpenseProcessStatus,
} from '@/types';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { allowNoContractOfSale } from '@/helpers/builder';
import { brokerLoanStartSchema } from '@/validation';
import { useForm } from 'vee-validate';

const props = defineProps<{
  from?: string;
  appType?: string;
  token?: string;
  broker?: string;
  utmMetrics: UtmMetricDto;
}>();

const customerAppProcess = useLoanAppProcess();
const brokerAppProcess = useBrokerLoanAppProcess();
const declarationProcess = useDeclarationProcess();
const requestIncomeExpenseProcess = useRequestIncomeExpenseProcess();

const isReady = ref(false);
const showModal = ref(false);
const modalInfo = ref({
  title: '',
  line1: '',
  line2: '',
});

const appKey = ref('');
const router = useRouter();

type BrokerLoanStartEntry = {
  complianceCheck?: boolean;
};

const { handleSubmit: validateSubmit } = useForm<BrokerLoanStartEntry>({
  validationSchema: brokerLoanStartSchema,
  initialValues: { complianceCheck: brokerAppProcess.loanApp.value.complianceCheck ?? false },
  validateOnMount: false,
});

const gotoNextStep = async (type?: 'broker' | 'customer') => {
  if (props.broker) {
    // verify token when coming from loggedIn broker
    const { data } = await api.backend.brokers.user.currentUser();
    if (data.broker) {
      const currentLoanApp = brokerAppProcess.state.value.context.loanApp;
      brokerAppProcess.state.value.context.loanApp = {
        ...currentLoanApp,
        brokerId: data.broker.id,
        brokerReferenceNumber: data.broker.brokerReferenceNumber,
        brokerEmail: data.broker.email,
      };
      return router.push({ name: 'broker' });
    }
  }
  const currentLoanApp = customerAppProcess.state.value.context.loanApp;
  customerAppProcess.state.value.context.loanApp = { ...currentLoanApp, utmMetrics: props.utmMetrics };
  type === 'broker'
    ? router.push({ name: 'broker' })
    : type === 'customer'
    ? router.push({ name: 'application' })
    : router.push({ name: 'declaration' });
};

const startNewApplication = () => {
  customerAppProcess.restart(false);
  gotoNextStep('customer');
};

const startExistingApplication = () => {
  const newState = JSON.parse(localStorage.getItem(appKey.value) as string);
  if (newState) {
    if (newState.loanApp.brokerId) {
      gotoNextStep('broker');
      brokerAppProcess.send({ type: 'RELOAD', state: newState });
    } else if (newState.loanApp.actionType === 'updateExisting' && newState.from !== 'crm') {
      declarationProcess.send({ type: 'RELOAD', state: newState });
      router.push({ name: 'declaration' });
    } else if (newState.from === 'crm') {
      router.push({ name: 'request' });
    } else {
      gotoNextStep('customer');
      declarationProcess.send({ type: 'RELOAD', state: newState });
    }
  } else {
    appKey.value = '';
  }
};

const gotoBrokerPortal = () => {
  window.location.href = import.meta.env.VITE_TECHLEND_BROKER_URL;
};

/**
 * when `allowNoContractOfSale` is true, redirect the user to customer application page and start new application
 * @returns {void}
 */
const validateAllowContractOfSale = (): void => {
  if (allowNoContractOfSale()) {
    startNewApplication();
  }
};

/**
 * When broker prop is present, proceeds to the next step for broker application
 * @returns {void}
 */
const handleStartBrokerApplication = validateSubmit(async (values) => {
  brokerAppProcess.send({ type: 'RESTART_BROKER', brokerId: '' });
  gotoNextStep();
});

const appApi = useApplicationApi();

onBeforeMount(async () => {
  isReady.value = false;
  try {
    if (props.broker) {
      updateToken(props.broker);
      brokerAppProcess.send({ type: 'RESTART_BROKER', brokerId: '' });
    } else if (['primary', 'secondary', 'broker'].includes(props.appType ?? '') && props.token) {
      updateToken(props.token);
      const result = await appApi.retrieveApplication();
      const authorisedUser = await api.backend.users.user.currentUser();

      if (result.success && authorisedUser.data.applicant) {
        const userType: AppUser =
          props.appType === 'primary' ? 'Primary' : props.appType === 'secondary' ? 'Secondary' : 'Broker';
        if (props.from !== 'crm') {
          const newState = initDeclarationMachine({
            actionType: 'updateExisting',
            userType,
            application: result.value || ({} as LoanApplication),
          });
          newState.authorisedUser = authorisedUser.data.applicant;
          newState.current = DeclarationProcessStep.Summary;
          newState.status = Object.fromEntries(
            DeclarationProcessStepNames.map((step) => [
              step,
              step === DeclarationProcessStep.Summary ? StepStatus.Ready : StepStatus.Incomplete,
            ]),
          ) as DeclarationProcessStatus;
          newState.loanApp = result.value || ({} as LoanApplication);
          // newState.targetApplicant = result.value?.applicants[1].id || '';
          newState.from = props.from; // is this an I&E request from the CRM?
          declarationProcess.send({ type: 'RELOAD', state: newState });
          router.push({ name: 'declaration' });
        } else {
          const newState = initRequestIncomeExpenseMachine({ actionType: 'updateExisting', userType });
          newState.authorisedUser = authorisedUser.data.applicant;
          newState.current = RequestIncomeExpenseProcessStep.Summary;
          newState.status = Object.fromEntries(
            RequestIncomeExpenseProcessStepNames.map((step) => [
              step,
              step === RequestIncomeExpenseProcessStep.Summary ? StepStatus.Ready : StepStatus.Incomplete,
            ]),
          ) as RequestIncomeExpenseProcessStatus;
          newState.loanApp = result.value || ({} as LoanApplication);
          newState.loanApp.userType = userType;
          // newState.targetApplicant = result.value?.applicants[1].id || '';
          newState.from = props.from; // is this an I&E request from the CRM?
          router.push({ name: 'request' });
          requestIncomeExpenseProcess.send({ type: 'RELOAD', state: newState });
        }
      } else {
        // const error = (<any>result).error;
        if (result.error === 'expired-link') {
          modalInfo.value = {
            title: 'Loan application link has expired',
            line1: 'Thank you! You have already confirmed your loan details.',
            line2: 'if you wish to speak to customer support.',
          };
        } else if (result.error === 'conversion-fail') {
          modalInfo.value = {
            title: 'Unable to retrieve loan details',
            line1: 'Sorry! We had trouble retrieving your loan details',
            line2: 'and talk to our customer support.',
          };
        } else {
          modalInfo.value = {
            title: 'Unable to retrieve loan details',
            line1: 'Sorry! There appears to be networking issues.',
            line2: 'if this problem persists.',
          };
        }
        showModal.value = true;
      }
    }
  } finally {
    isReady.value = true;
    validateAllowContractOfSale();
  }
});

const trustpilot = ref();
onMounted(() => {
  if (window.Trustpilot) {
    window.Trustpilot.loadFromElement(trustpilot.value, true);
  }
  appKey.value = localStorage.getItem(CurrentKey) ?? '';
});
</script>

<script lang="ts">
declare global {
  interface Window {
    // eslint-disable-next-line
    Trustpilot: any;
  }
}
</script>

<template>
  <div class="flex w-full flex-col items-center">
    <div v-if="!isReady" class="mt-[20%] text-center">
      <BxSpinner size="4rem" />
      <div>Loading application</div>
    </div>
    <div v-else class="mt-16 grid w-full place-items-center">
      <div
        class="invisible absolute w-full rounded border border-gray-100 bg-white p-8 opacity-0 shadow-lg transition-opacity duration-500 sm:w-3/4 lg:w-1/2"
        :class="appKey && '!visible !static !opacity-100'"
      >
        <div class="flex flex-col items-center text-center">
          <h1 class="w-full text-center text-2xl font-medium">
            Welcome to the {{ AppValues.COMPANY_NAME }} Application
          </h1>
          <div v-if="props.broker" class="w-full">
            <div class="mb-4 mt-8 flex w-full flex-col items-center rounded-lg p-4 pt-0">
              <ExclamationTriangleIcon class="h-10 w-10 text-amber-400" />
              <div class="flex flex-col text-left">
                <span class="mt-4">
                  By continuing with this application, you confirm that you have completed all necessary aggregator
                  compliance requirements, including but not limited to:
                </span>
                <ul class="ml-0 mt-2 list-none sm:ml-8">
                  <li><span>Privacy and Consent form signed by client/s.</span></li>
                  <li><span>Credit Guide provided to the client/s.</span></li>
                  <li>
                    <span>Reasonable Enquiries and Responsible Lending Requirements have been met under NCCP.</span>
                  </li>
                  <li><span>Best Interests Duty requirements have completed.</span></li>
                </ul>
                <div class="mt-8">
                  <BxField v-slot="{ field }" name="complianceCheck">
                    <BxCheckbox
                      id="broker-followed-requirements"
                      v-bind="field"
                      label="Please tick to confirm that you have met all compliance requirements."
                    />
                  </BxField>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="w-full">
            <div class="mb-4 mt-8 flex w-full flex-col items-center rounded-lg border border-gray-300 p-4 text-center">
              <ExclamationTriangleIcon class="h-10 w-10 text-amber-400" />
              <span class="mt-2 text-lg font-medium">We found an application still in progress</span>
            </div>
            <span>Would you like to continue the current application <br />or start a new one?</span>
          </div>
        </div>
        <div v-if="props.broker" class="mt-6">
          <BxButton
            id="broker-start-application"
            class="bullet-item justify-center"
            variant="primary"
            @click="handleStartBrokerApplication()"
            >Start application</BxButton
          >
        </div>
        <div v-else class="mt-6 flex flex-wrap gap-6">
          <BxButton class="bullet-item justify-center" variant="primary" @click="startExistingApplication()">
            Continue
          </BxButton>
          <BxButton class="bullet-item justify-center" variant="primary" @click="appKey = ''">Start new</BxButton>
        </div>
      </div>
      <div
        class="invisible absolute w-full rounded border border-gray-100 bg-white p-8 opacity-0 shadow-lg transition-opacity duration-500 sm:w-3/4 lg:w-1/2"
        :class="!appKey && '!visible !static !opacity-100'"
      >
        <h1 class="w-full text-center text-2xl font-medium">Welcome to the {{ AppValues.COMPANY_NAME }} Application</h1>
        <div class="mt-8 flex flex-wrap gap-6">
          <div class="bullet-item">
            <div>
              <BxIcon name="stopwatch" class="h-8 w-8" />
            </div>
            <span>5 to 10 minutes to apply</span>
          </div>
          <div class="bullet-item">
            <div>
              <BxIcon name="docSearch" class="h-8 w-8" />
            </div>
            <span>No credit check until you qualify</span>
          </div>
          <div class="bullet-item">
            <div>
              <BxIcon name="padlock" class="h-8 w-8" />
            </div>
            <span>Information is protected by bank&#8209;level security</span>
          </div>
          <div class="bullet-item">
            <div>
              <BxIcon name="circleTick" class="h-8 w-8" />
            </div>
            <span>No obligation to proceed after applying</span>
          </div>
        </div>
        <div class="mt-8 flex flex-wrap gap-6">
          <BxButton
            id="customer-type-customer"
            class="bullet-item justify-center"
            variant="primary"
            @click="startNewApplication"
          >
            I'm a Customer
          </BxButton>
          <BxButton
            id="customer-type-broker"
            class="bullet-item justify-center"
            variant="primary"
            @click="gotoBrokerPortal()"
          >
            I'm a Broker
          </BxButton>
        </div>
        <div class="mt-4">
          <!-- TrustBox widget - Micro TrustScore -->
          <div
            ref="trustpilot"
            class="trustpilot-widget"
            data-locale="en-US"
            data-template-id="5419b637fa0340045cd0c936"
            data-businessunit-id="6216ad9ba106dfb98a8b5eaf"
            data-style-height="20px"
            data-style-width="100%"
            data-theme="light"
          >
            <a href="https://www.trustpilot.com/review/bridgit.com.au" target="_blank" rel="noopener">Trustpilot</a>
          </div>
          <!-- End TrustBox widget -->
        </div>
      </div>
    </div>
    <BxModal
      :open="showModal"
      :icon="ExclamationTriangleIcon"
      :title="modalInfo.title"
      cancel-label="Cancel"
      confirm-label="Confirm"
    >
      <div class="mt-6 leading-loose">
        <p>{{ modalInfo.line1 }}</p>
        <p>
          Please contact {{ AppValues.COMPANY_NAME }} on
          <a class="link" :href="`tel:${AppValues.CONTACT}`">{{ AppValues.CONTACT }}</a>
          {{ modalInfo.line2 }}.
        </p>
      </div>
      <template #buttons>
        <BxButton variant="primary" @click="showModal = false">Ok</BxButton>
      </template>
    </BxModal>
  </div>
</template>

<style lang="scss" scoped>
.bullet-item {
  display: flex;
  flex-basis: 100%;
  gap: 0.75rem;
  align-items: center;

  @media (min-width: 640px) {
    flex-basis: calc(50% - 0.75rem);
  }
}

.link {
  @apply cursor-pointer font-medium text-bridgit-navy underline hover:text-bridgit-vibrantGreen;
}

ul {
  li {
    @apply mt-2;
    &::before {
      @apply text-bridgit-vibrantGreen;
      @apply mr-[-1.125rem] rounded-full font-black;
      content: '✓';
      padding: 2px 4px;
      font-size: 10px;
      background-color: #eeecec;
    }
  }
  span {
    @apply relative  left-8 top-[2px] mr-8 inline-flex;
  }
}
</style>
